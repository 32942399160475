import { Directive, Input, ViewChild, OnInit, OnDestroy } from '@angular/core';
import { NgbDropdown } from '@ng-bootstrap/ng-bootstrap';

@Directive({
  selector: '[appDropdownPosition]',
  standalone: true,
})
export class AppDropdownPositionDirective implements OnInit, OnDestroy {
  @Input('appDropdownPosition') dropdown: NgbDropdown;

  constructor() {
    this.checkPosition = this.checkPosition.bind(this);
  }

  public ngOnInit() {
    window.addEventListener('resize', this.checkPosition);
    this.checkPosition();
  }

  public ngOnDestroy() {
    window.removeEventListener('resize', this.checkPosition);
  }

  private checkPosition(): void {
    if (!this.dropdown) {
      return;
    }

    if (window.screen.width < 800) {
      this.dropdown.container = 'body';
      this.dropdown.placement = 'left';
    } else {
      this.dropdown.container = null;
      this.dropdown.placement = 'bottom-left bottom-right top-left top-right';
    }
  }
}
